<template>
    <div class="company-contain">
        <div class="step-box">
            <div class="step-box-contain">
                <img :src="activeName === '1'?require('../../../assets/img/company/step1_active.png'):require('../../../assets/img/company/step1.png')"
                     alt="">
                <div class="show-box">
                    <div class="num-box">1</div>
                    <div class="text-box">任务要求</div>
                </div>
            </div>
            <div class="step-box-contain">
                <img :src="activeName === '2'?require('../../../assets/img/company/step2_active.png'):require('../../../assets/img/company/step2.png')"
                     alt="">
                <div class="show-box">
                    <div class="num-box">2</div>
                    <div class="text-box">选择学生</div>
                </div>
            </div>
        </div>
        <div class="company-contain-box" v-show="activeName ==='1'">
            <div class="head-box">
                <div class="title" v-if="this.form.id==''">发布任务</div>
                <div class="title" v-else>编辑任务</div>
            </div>
            <div class="divide">
                <el-divider></el-divider>
            </div>
            <!--筛选条件-->
            <div class="filter-box">
                <div class="box1">
                    <p>任务分类：</p>
                    <span @click="isAllS(item.id)"
                          :class="{activeClass:form.tasktype == item.id}"
                          v-for="item in typeLsit"
                          v-model=title
                    >{{item.title}}</span>
                </div>
            </div>
            <div class="table-box">
                <el-form :model="form" ref="form" label-width="110px" :rules="rules">
                    <el-form-item label="任务标题：" prop="title">
                        <el-input
                                type="text"
                                show-word-limit
                                v-model="form.title"
                                style="width:300px"
                                maxlength="30"
                                placeholder="请输入任务标题"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="任务描述：" prop="describe">
                        <el-input
                                v-model="form.describe"
                                style="width:300px"
                                type="textarea"
                                placeholder="请输入内容"
                                maxlength="150"
                                show-word-limit
                        ></el-input>
                    </el-form-item>

                    <el-form-item style="position: relative;" label="任务封面：" prop="cover">
                        <div class="upload-box">
                            <div class="upload-main" onclick="UploadInput.click()">
                                <i class="el-icon-circle-plus"></i>
                                <span>点击上传</span>
                                <span>支持jpg/png格式</span>
                            </div>
                            <input
                                    ref="fileInput"
                                    type="file"
                                    id="UploadInput"
                                    @change="uploadFile($event)"
                                    style="display:none"
                            />
                            <div class="img-box" v-if="form.cover !==''">
                                <img class="cover-box" :src="form.cover" alt="">
                            </div>
                        </div>
                        <span class="imggeshi">备注：上传封面</span>
                        <span class="imggeshi1">尺寸358X206</span>
                    </el-form-item>

                    <el-form-item label="截止时间：" prop="time">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                                        v-model="form.time"
                                        type="datetime" placeholder="选择日期时间"></el-date-picker>
                    </el-form-item>

                    <el-form-item label="限制人数：" prop="Islimit" v-if="form.apply !=3">
                        <el-radio-group v-model="form.Islimit">
                            <el-radio :label="0">不限制</el-radio>
                            <el-radio :label="1">限制人数</el-radio>
                        </el-radio-group>
                        <el-input style="width: 150px;margin-left: 20px;" placeholder="请输入限制人数"
                                  v-show="this.form.Islimit == 1" v-model="form.limitnum"
                                  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="任务金额：" prop="taskmoney">
                        <el-input placeholder="请输入任务金额" style="width:200px;" v-model="form.taskmoney"></el-input>
                    </el-form-item>

                    <el-form-item label="报名需求：" prop="apply">
                        <el-select v-model="form.apply" placeholder="请选择是否报名">
                            <el-option label="需要报名" :value="1"></el-option>
                            <el-option label="无需报名" :value="0"></el-option>
                            <el-option label="合作咨询" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合作邀请：" v-show="form.apply === 3">
                        <el-input
                                v-model="form.cooperation_content"
                                style="width:300px"
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 4}"
                                placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                    <el-form-item v-if="this.form.tasktype == 6" label="选择商品">
                        <el-button size="small" type="primary" @click="chooseGoods">选择商品</el-button>
                    </el-form-item>

                    <el-form-item label="上传附件：" prop="fil">
                        <el-upload
                                class="upload-demo"
                                action="string"
                                accept=".doc,.pdf"
                                ref="upload1"
                                multiple
                                :limit="3"
                                :before-upload="beforeUploadFile"
                                :before-remove="beforeRemove"
                                :http-request="UploadImageTwo"
                                :file-list="fileLss"
                                style="display: flex;"
                        >
                            <el-button size="small" type="primary">上传附件</el-button>
                        </el-upload>
                        <span style="color: #666;font-size: 12px">支持上传文件类型为doc、pdf</span>
                    </el-form-item>
                </el-form>
            </div>
            <el-dialog
                    title="选择商品"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="closeDialog">
                <el-table :data="productList">
                    <el-table-column label="选择商品" align="center" width="100">
                        <template slot-scope="scope">
                            <el-radio v-model="radio" :label="scope.row.goods_id"
                                      @change="handleSelect(scope.row.goods_id)">{{" "}}
                            </el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column property="goods_name" align="center" label="商品信息" width="200"></el-table-column>
                    <el-table-column property="goods_price" align="center" label="商品价格"></el-table-column>
                </el-table>
                <el-pagination
                        class="pages-center"
                        :current-page="adminPages.currentPageNum"
                        :page-size="adminPages.eachPageNum"
                        layout="prev, pager, next, jumper"
                        :total="adminPages.total"
                        @current-change="adminCurrentChange"
                ></el-pagination>
                <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="choosed">添 加</el-button>
                <el-button @click="closeDialog">取 消</el-button>
            </span>
            </el-dialog>
        </div>
        <div class="stu-box">
            <stu-expert :activeName="activeName" :stuId="editForm.user_account_ids" @picList="handlePic"
                        @videoList="handleVideo" @saveForm="saveForm" v-if="activeName ==='2'"/>
        </div>
        <div class="footer-box">
            <el-button type="primary" v-if="form.apply === 3 && activeName === '1'" size="small"
                       @click="chooseStu('form')">选择学生
            </el-button>
            <el-button type="primary" v-if="form.apply !== 3 || activeName === '2'" size="small"
                       @click="AddTask('form')">发布任务
            </el-button>
            <el-button size="small" v-if="activeName === '1'" @click="handleClose('form')">取消</el-button>
            <el-button size="small" v-else @click="goBack('form')">返回</el-button>
            <el-button size="small" @click="returnBack()">返回列表</el-button>
        </div>
    </div>
</template>

<script>
    import StuExpert from "../product/StuExpert";
    import {mapGetters, mapMutations} from 'vuex'

    export default {
        name: 'taskPublish',
        components: {
            StuExpert
        },
        data() {
            return {
                activeName: "1",
                radio: "",
                dialogVisible: false,
                fileUrl: [],
                title: "",//类型名称
                typeLsit: [], //分类列表
                taskList: [],//任务列表
                productList: [],//商品数据
                rules: {
                    title: [
                        {required: true, message: "请输入任务标题", trigger: "blur"},
                        {min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur"}
                    ],
                    describe: [
                        {required: true, message: "请输入任务描述", trigger: "blur"},
                        {
                            min: 3,
                            max: 150,
                            message: "长度在 3 到 150 个字符",
                            trigger: "blur"
                        }
                    ],
                    cover: [{required: true, message: "请上传封面", trigger: "blur"}],
                    time: [
                        {
                            required: true,
                            message: "请选择截止时间",
                            trigger: "change"
                        }
                    ],
                    Islimit: [
                        {required: true, message: '请选择是否限制人数', trigger: 'change'},
                    ],
                    taskmoney: [
                        {required: true, message: "请输入任务金额", trigger: "blur"},
                        {
                            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                            message: '请输入正确格式,可保留两位小数'
                        },
                    ],
                    apply: [
                        {required: true, message: '请选择是否报名', trigger: 'change'}
                    ],
                },
                form: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是 3合作咨询
                    user_account_ids: '',//学生id apply为3时必传
                    cooperation_content: '',
                    file: [], //附件,
                    goods_id: ""//商品
                },
                fileList: [],
                fileLss: [],
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [], //附件
                    user_account_ids: '',//学生id apply为3时必传
                    cooperation_content: '',
                },
                isSelected: false,
                picList: [],
                videoList: [],
            }
        },
        mounted() {
            this.getTaskType();
            this.IsEdit();
            this.checkIsBack()
        },
        computed: {
            ...mapGetters(['getTaskForm'])
        },
        methods: {
            ...mapMutations(['setTaskForm']),
            checkIsBack() {
                let res = this.$route.query.isBack;
                if (res) {
                    this.form = this.getTaskForm;
                    this.activeName = '2';
                }
            },
            handlePic(val) {
                this.picList = val;
            },
            handleVideo(val) {
                this.videoList = val;
            },
            saveForm(val) {
                this.setTaskForm(this.form)
            },
            //选择任务分类
            isAllS(id) {
                this.form.tasktype = id;
            },
            getTaskType() {
                let params = {};
                this.$httpcompany.axiosGetBy(this.$api.taskTypeList, params, res => {
                    this.typeLsit = res;
                });
            },
            IsEdit() {
                let task_id = this.$route.query.task_id;
                if (task_id !== undefined) {
                    let param = {
                        'task_id': task_id
                    }
                    this.$httpcompany.axiosGetBy(this.$api.taskEdit, param, (res) => {
                        if (res.code == 200) {
                            const resInfo = res.info
                            this.editForm.id = resInfo.task_id;
                            if (resInfo.task_type_id == 6) {
                                this.editForm.goods_id = resInfo.goods_id;
                            }
                            this.editForm.tasktype = resInfo.task_class;
                            this.editForm.title = resInfo.task_title;
                            this.editForm.describe = resInfo.task_content;
                            this.editForm.cover = resInfo.task_cover_file;
                            this.editForm.time = resInfo.task_end_time;
                            this.editForm.Islimit = resInfo.task_is_limit;
                            this.editForm.limitnum = resInfo.task_limit_num;
                            this.editForm.taskmoney = resInfo.task_money;
                            this.editForm.apply = resInfo.task_is_apply;
                            if (resInfo.stuIntelligent) {
                                this.editForm.user_account_ids = resInfo.stuIntelligent.user_account_ids;
                            }
                            this.editForm.cooperation_content = resInfo.cooperation_content;
                            if (resInfo.task_file.length) {
                                for (let i = 0; i < resInfo.task_file.length; i++) {
                                    delete resInfo.task_file[i].id;
                                    delete resInfo.task_file[i].task_id;
                                }
                                this.editForm.file = resInfo.task_file;
                                this.editForm.file = JSON.parse(JSON.stringify(this.editForm.file).replace(/file_url/g, "file_path"))
                            }
                            this.form = this.editForm;
                            this.radio = this.form.goods_id;
                            this.fileList = [{url: this.form.cover}];
                            this.fileLss = this.form.file;
                            this.fileLss.forEach(item => {
                                item.name = item.file_name;
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    })
                }
            },
            checkStatus() {
                if (this.form.tasktype == "") {
                    this.$message({
                        message: "请选择类型！",
                        type: "warning"
                    })
                    return false;
                }
                if (this.form.Islimit == 1 && this.form.limitnum == "") {
                    this.$message({
                        message: "请输入限制人数！",
                        type: "warning"
                    })
                    return false;
                }
                if (this.form.tasktype == 6 && this.form.goods_id == "") {
                    this.$message({
                        message: "请选择商品！",
                        type: "warning"
                    })
                    return false;
                }
                return true;
            },
            chooseStu(formName) {
                let res = this.checkStatus();
                if (res == false) {
                    return res
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.activeName = '2';
                    } else {
                        return false
                    }
                })
            },
            //发布任务
            AddTask(formName) {
                let res = this.checkStatus();
                if (res == false) {
                    return res
                }
                if (this.form.apply === 3) {
                    if (this.picList.length === 0 && this.videoList.length === 0) {
                        this.$message({
                            message: "请选择学生！",
                            type: "warning"
                        })
                        return false;
                    }
                }
                let arr = [];
                this.picList.forEach(item => {
                    if (item) {
                        arr.push(item.user_account_id)
                    }
                })
                this.videoList.forEach(item => {
                    if (item) {
                        arr.push(item.user_account_id)
                    }
                })
                this.form.user_account_ids = arr.join(',');
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //编辑
                        if (this.form.id != "") {
                            let formData = new FormData();
                            formData.append("task_id", this.form.id)
                            formData.append("task_type", 2)
                            formData.append('task_class', this.form.tasktype);
                            formData.append('task_title', this.form.title);
                            formData.append('task_content', this.form.describe);
                            formData.append('task_cover', this.form.cover);
                            formData.append('task_end_time', this.form.time);
                            formData.append('task_is_limit', this.form.Islimit);
                            formData.append('task_limit_num', this.form.limitnum);
                            formData.append('task_money', this.form.taskmoney);
                            formData.append('task_is_apply', this.form.apply);
                            formData.append('cooperation_content', this.form.cooperation_content);
                            formData.append('user_account_ids', this.form.user_account_ids);
                            formData.append('file', JSON.stringify(this.form.file));
                            if (this.form.tasktype == 6) {
                                formData.append('goods_id', this.form.goods_id);
                            }
                            this.$httpcompany.axiosPost(this.$api.task_save, formData, (res) => {
                                if (res.code == 200) {
                                    this.$message.success('编辑成功！');
                                    this.editForm = {};
                                    this.handleClose('form')
                                } else {
                                    this.$message.warning('编辑失败')
                                }
                            }, err => {
                                this.$message.warning('编辑失败')
                            })
                        } else {
                            let formData = new FormData();
                            formData.append('task_class', this.form.tasktype);
                            formData.append("task_type", 2)
                            formData.append('task_title', this.form.title);
                            formData.append('task_content', this.form.describe);
                            formData.append('task_cover', this.form.cover);
                            formData.append('task_end_time', this.form.time);
                            formData.append('task_is_limit', this.form.Islimit);
                            formData.append('task_limit_num', this.form.limitnum);
                            formData.append('task_money', this.form.taskmoney);
                            formData.append('task_is_apply', this.form.apply);
                            formData.append('cooperation_content', this.form.cooperation_content);
                            formData.append('user_account_ids', this.form.user_account_ids);
                            formData.append('file', JSON.stringify(this.form.file));
                            if (this.form.tasktype == 6) {
                                formData.append('goods_id', this.form.goods_id);
                            }
                            this.$httpcompany.axiosPost(this.$api.task_save, formData, (res) => {
                                if (res.code == 200) {
                                    this.$message.success('发布成功！');
                                    this.editForm = {};
                                    this.handleClose('form')
                                } else {
                                    this.$message.warning('发布失败')
                                }
                            }, err => {
                                this.$message.warning('发布失败')
                            })
                        }
                    }
                })
            },
            goBack() {
                this.activeName = '1'
            },
            //取消
            handleClose(formname) {
                this.$refs[formname].resetFields();
                this.fileLss = [];
                this.fileList = [];
                this.form.tasktype = "";
                this.form.id = "";
                this.form.goods_id = "";
                this.picList = [];
                this.videoList = [];
                this.$router.push('/company/taskcenter/index')
            },
            closeDialog() {
                this.dialogVisible = false;
                if (this.isSelected == false) {
                    this.radio = "";
                }
            },
            chooseGoods() {
                this.dialogVisible = true;
                this.getGoodsList();
            },
            handleSelect(val) {
                this.form.goods_id = val;
            },
            //短视频推广选择商品
            choosed() {
                this.dialogVisible = false;
                this.isSelected = true;
            },
            // 获取商品列表
            getGoodsList() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    type: 0
                };
                this.$httpcompany.axiosGetBy(this.$api.goodsList, param, res => {
                    if (res.code == 200) {
                        this.productList = res.list;
                        this.adminPages.total = res.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });

            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getGoodsList();
            },
            returnBack() {
                this.$router.push({path: "/company/taskcenter/index"});
                this.editForm = {};
            },
            //上传封面
            uploadFile(event) {
                let that = this;
                let fileName = event.target.files[0].name;
                let file = event.target.files[0];
                let limiteFileType = ["jpg", "png"];
                if (
                    !limiteFileType.includes(
                        fileName.split(".")[fileName.split(".").length - 1]
                    )
                ) {
                    this.$message.warning("文件格式错误！");
                    return;
                }
                const isLt1M = file.size / 1024 / 1024 < 1;
                const isSize = new Promise(function (resolve, reject) {
                    let width = 358
                    let height = 206
                    let _URL = window.URL || window.webkitURL
                    let img = new Image()
                    img.onload = function () {
                        let valid = img.width == width && img.height == height
                        valid ? resolve() : reject();
                    }
                    img.src = _URL.createObjectURL(file)
                }).then(() => {
                    let formData = new FormData();
                    formData.append("file", file);
                    this.$httpcompany.axiosPost(this.$api.uploadImg, formData, res => {
                            if (res.code == 200) {
                                that.form.cover = res.data.src;
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg,
                                    duration: 1000
                                });
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }, () => {
                    this.$message.error('上传图片尺寸限制为358*206!');
                    return Promise.reject();
                }).catch((e) => {
                })
            },

            UploadImageTwo(param) {
                const formData = new FormData();
                formData.append("file", param.file);
                this.$httpcompany.axiosPost(
                    this.$api.uploadFile,
                    formData,
                    res => {
                        if (res.code == 200) {
                            this.fileUrl = res.url;
                            const fileObj = {
                                "file_name": param.file.name,
                                "file_path": this.fileUrl
                            };
                            this.form.file.push(fileObj);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },

            beforeUploadFile(file) {
                let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
                const extension = testmsg === 'doc'
                const extension2 = testmsg === 'pdf'
                if (!extension && !extension2) {
                    this.$message({
                        message: '上传文件只能是 doc、pdf格式!',
                        type: 'warning'
                    });
                    return false;
                }
                return extension || extension2
            },

            //移除之前
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.fileLss.splice(file, 1)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                });
            },
        }

    }
</script>

<style scoped lang="scss">
    ::v-deep .el-tabs__header {
        background: #F3F3F6;
    }

    ::v-deep .el-button--primary {
        background: #4D5AFF;
        border-radius: 2px;
        color: #ffffff;
        text-align: center;
    }

    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        background-color: #ffffff;
        margin-top: 75px;
        position: relative;

        .step-box {
            position: absolute;
            width: 100%;
            height: 55px;
            top: -75px;
            display: flex;

            .step-box-contain {
                position: relative;

                .show-box {
                    position: absolute;
                    top: 16px;
                    left: 168px;
                    display: flex;

                    .num-box {
                        width: 22px;
                        height: 22px;
                        background: #FFFFFF;
                        border-radius: 50%;
                        color: #20C997;
                        font-size: 18px;
                        line-height: 23px;
                        text-align: center;
                        margin-top: 2px;
                    }

                    .text-box {
                        margin-left: 20px;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }
            }

        }

        .head-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 10px 30px 0 20px;

            .title {
                margin-top: 10px;
            }
        }

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .table-box {
            padding-bottom: 50px;
        }

        .imggeshi {
            position: absolute;
            left: -88px;
            top: 24px;
            color: #999999;
            font-size: 12px;
        }

        .imggeshi1 {
            position: absolute;
            left: -88px;
            top: 42px;
            color: #999999;
            font-size: 12px;
        }

        .upload-box {
            display: flex;

            .upload-main {
                width: 358px;
                height: 206px;
                cursor: pointer;
                background: #F1F1F1;
                color: #4D5AFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 36px;
                }

                span {
                    color: #333333;
                }
            }

            .showImg-box {
                margin-left: 20px;

                &:nth-child(1) {
                    margin-left: 0px;
                }

                .logo-box {
                    width: 100px;
                    height: 100px;
                    border: 1px solid #EEEEEE;
                    border-radius: 2px;
                }

                .qa-box {
                    max-width: 154px;
                    max-height: 212px;
                }
            }

            .img-box {
                margin-left: 24px;
                position: relative;
            }
        }

        .stu-box {
            flex: 1;
        }

        .footer-box {
            width: 100%;
            justify-content: center;
            display: flex;
            padding-bottom: 20px;

            ::v-deep .el-button {
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
</style>
